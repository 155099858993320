// ** Logo
import logo from '@src/assets/images/logo/nccicon.png'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import async from 'react-select/async'
import api from '../../../services/api'

const SpinnerComponent = () => {

  useEffect( async () =>{

    try{

      const response = await api.get('/user/refresh/token')

      const cred = {
          idcred: response.data.id,
          type: response.data.type
      }

      Cookies.set('lguser', 1, { expires: 1 })
      console.log(response)
      Cookies.set('ttp_s', JSON.stringify(cred), { expires: 1 })

    }catch(err){
      console.log(err)
      Cookies.remove('lguser')
      Cookies.remove('ttp_s')
    }

  }, [])

  return (
    <div className='fallback-spinner vh-100'>
      <img className='fallback-logo' width="65" height="65" src={logo} alt='logo' />
      <div className='loading'>
        <div className='effect-1 effects'></div>
        <div className='effect-2 effects'></div>
        <div className='effect-3 effects'></div>
      </div>
    </div>
  )
}

export default SpinnerComponent
